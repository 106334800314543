.BotResponse {
  word-wrap: break-word;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 17px;
  white-space: pre-wrap;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  flex-shrink: 0;
  border-bottom-left-radius: 5px;
}
