.certificates__section {
  padding: 15px;
}

.certificates__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px 10px;
  margin-top: 60px;
  width: 100%;
  margin-bottom: 100px;
}

.take-level-test {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  background-color: #fff;
  padding: 15px 20px 15px 15px;
  border-radius: 10px;
  margin: 20px 0 70px;
  flex-direction: column;
}

.btn-take-test {
  padding: 8px 16px;
  background: #ffffff;
  border-radius: 8px;
  font-weight: 800;
  font-size: 18px;
  color: #723fb1;
  border: 2px solid #723fb1;
  width: 100%;
}

.take-level-test > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.take-level-test img {
  width: 60px;
}

.current-level--legend {
  font-weight: 500;
  font-size: 14px;
  color: #666666;
  display: block;
  margin-bottom: -3px;
}

.current-level {
  font-weight: 700;
  font-size: 14px;
  color: #8d5dc6;
  display: block;
}

@media screen and (min-width: 1024px) {
  .certificates__container {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 30px;
  }

  .certificates__section {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .take-level-test {
    align-items: center;
    gap: 40px;
    margin: 20px 0 0;
    flex-direction: row;
  }

  .btn-take-test {
    width: auto;
  }
}
