.Caption {
  align-items: center;
  display: flex;
  max-width: 100%;
  gap: 5px;
}

.Caption img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
