.index-container {
  padding: 0 0 0 250px;
  transition: padding 300ms ease;
}

.expand-container {
  padding: 0 0 0 96px;
}

@media screen and (max-width: 1023px) {
  .index-container {
    padding: 0;
  }
}
