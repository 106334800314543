.Backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  position: relative;
}

.Wrapper {
  width: 100%;
  height: 100%;
  max-width: 564px;
  background-color: white;
  border-radius: 19px 0 0 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.ImageWrapper {
  position: relative;
  width: 600px;
}

.WrapperStoreButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.Image {
  width: 100%;
  height: 600px;
  border-radius: 0px 19px 19px 0px;
}

.NotebookImage {
  width: 694px;
  height: 507px;
  top: 55px;
  right: 0px;
  position: absolute;
  z-index: 2;
}

.WrapperButtonClose {
  position: absolute;
  top: 10px;
  right: 12px;
}

.ButtonClose {
  background-color: white;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.WrapperContent {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
}

.SubTitle {
  font-size: 12px;
  font-weight: 600;
  color: #6a51b2;
  text-align: center;
}

.Title {
  line-height: 39px;
  font-weight: 800;
  color: #292a2c;
  text-align: center;
  font-size: 36px;
}

.Bold {
  color: #6c3ca7;
  font-weight: 800;
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 50px;
  padding: 20px;
  gap: 10px;
  background-color: #6c3ca7;
  border-radius: 99px;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.Description {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
  color: #767676;
  margin-bottom: 24px;
}

.WrapperCursiveLine {
  display: block;
  position: absolute;
  bottom: -38px;
}

@media (max-width: 1024px) {
  .Wrapper {
    width: 400px;
  }

  .Title {
    font-size: 32px;
  }

  .WrapperCursiveLine {
    display: none;
  }
}
