.recoveryPassword h6 {
  color: #383743;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4em;
}

.recoveryPassword p {
  color: #66647a;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  margin-bottom: 22px;
  margin-top: 6px;
}

.recoveryPassword form input {
  font-size: 0.8em;
  letter-spacing: 0.25px;
}
