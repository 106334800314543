.container {
  margin: 0 auto;
  max-width: 730px;
  height: 100vh;
  font-family: "Nunito", sans-serif !important;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

.animation {
  pointer-events: none;
  height: 100vh;
}

.containerShorts {
  margin: 0 auto;
  max-width: 700px;
  height: 100vh;
  font-family: "Nunito", sans-serif !important;
}

.wrapperHeader {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 500px) {
  .container {
    padding: 20px;
    padding-top: 5px;
  }

  .containerShorts {
    padding: 0;
  }

  .animation {
    position: absolute;
  }
}

.adFooterContent .adsbygoogle {
  overflow: hidden;
  display: block;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;

  height: 70px;
  max-height: 90px;
  width: 728px;
}

.adFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  overflow: hidden;
  display: block;
  position: relative;
}

@media screen and (max-width: 800px) {
  .adFooter .adFooterContent .adsbygoogle {
    height: 50px;
    max-height: 50px;
    width: 320px;
  }
}

@media screen and (max-width: 200px) {
  .adFooter .adFooterContent .adsbygoogle {
    display: none;
  }
}
