.sidebar-profile {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.profile__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile__picture {
  width: 97px;
  height: 108px;
  background-size: cover;
  background-position: center center;
  clip-path: path(
    "M43.1115 2.08113C46.5211 0.112603 50.7219 0.112603 54.1315 2.08113L90.8294 23.2687C94.239 25.2372 96.3394 28.8752 96.3394 32.8123L96.3394 75.1874C96.3394 79.1245 94.239 82.7625 90.8294 84.731L54.1315 105.919C50.7219 107.887 46.5211 107.887 43.1115 105.919L6.41356 84.731C3.00396 82.7625 0.903566 79.1245 0.903566 75.1874L0.903565 32.8123C0.903565 28.8752 3.00396 25.2372 6.41356 23.2687L43.1115 2.08113Z"
  );
}

.profile__border {
  width: 107px;
  height: 120px;
  background-color: #eee;
  clip-path: path(
    "M47.429 1.65902C51.2603 -0.553009 55.9808 -0.553009 59.8121 1.65902L101.05 25.4674C104.881 27.6795 107.241 31.7675 107.241 36.1916L107.241 83.8084C107.241 88.2325 104.881 92.3205 101.05 94.5325L59.8121 118.341C55.9808 120.553 51.2603 120.553 47.429 118.341L6.19157 94.5325C2.36021 92.3205 1.64987e-06 88.2325 1.56715e-06 83.8084L6.76751e-07 36.1916C5.94025e-07 31.7675 2.36021 27.6795 6.19157 25.4675L47.429 1.65902Z"
  );
  position: absolute;
}

.profile__picture--small {
  width: 56.95px;
  height: 63.73px;
  background-size: cover;
  background-position: center center;
  clip-path: path(
    "M25.7126 1.01537C27.7474 -0.159421 30.2543 -0.159421 32.2891 1.01537L54.19 13.6598C56.2248 14.8346 57.4782 17.0057 57.4782 19.3553L57.4782 44.6442C57.4782 46.9938 56.2248 49.1649 54.19 50.3397L32.2891 62.9841C30.2543 64.1589 27.7474 64.1589 25.7126 62.9841L3.81173 50.3397C1.77693 49.1649 0.523438 46.9938 0.523438 44.6442L0.523438 19.3553C0.523438 17.0057 1.77693 14.8346 3.81172 13.6598L25.7126 1.01537Z"
  );
}

.profile__border--small {
  width: 64.71px;
  height: 71.61px;
  background-color: #eee;
  clip-path: path(
    "M28.305 0.990083C30.5915 -0.330028 33.4085 -0.330028 35.695 0.990083L60.305 15.1986C62.5915 16.5187 64 18.9584 64 21.5986L64 50.0157C64 52.6559 62.5915 55.0956 60.305 56.4157L35.695 70.6242C33.4085 71.9444 30.5915 71.9444 28.305 70.6242L3.69504 56.4157C1.40854 55.0956 9.84621e-07 52.6559 9.35251e-07 50.0157L4.03876e-07 21.5986C3.54506e-07 18.9584 1.40854 16.5187 3.69504 15.1986L28.305 0.990083Z"
  );
  position: absolute;
}

.profile__username {
  font-size: 18px;
  font-weight: 800;
  color: #292a2c;
}

.profile__see-profile {
  color: #6a51b2;
  font-weight: 800;
  font-size: 16px;
  margin-top: -5px;
}

.sidebar__level {
  background-color: #f7f7fa;
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  gap: 5px;
  border-radius: 6px;
  font-weight: 500;
  height: 39px;
  overflow-y: hidden;
  color: #8d5dc6;
  font-size: 0.9em;
}

.sidebar__level img {
  width: 20.66px;
}

.sidebar__level__icon svg {
  height: 23px;
  width: 23px;
}

.fade-in-level {
  opacity: 0;
  overflow-y: hidden;
  animation: fade-in-level 1s ease forwards;
}

@keyframes fade-in-level {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-height: 900px) {
  .sidebar-profile {
    height: auto;
  }
}
