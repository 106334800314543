@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

* {
  outline: none !important;
  --light-purple: #c3a2f5;
  --aqua-green: #14c4be;
  --light-aqua-green: #d6ffff;
  --dark-purple: #30125c;
  --light-gray: #676482;
  --gray: #383743;
  --dark-gray: #1a1738;
  --gray: #f3f4f6;
  --light-gray: #66647a;
  --pb-black: #1a1738;
}

#root {
  font-family: "Urbanist", sans-serif;
}

body {
  background: #f7f7fa;
}

#eb-ad-slot {
  display: none;
}

ins {
  display: block;
}

.font-gray {
  color: var(--gray);
}
.font-light-gray {
  color: var(--light-gray);
}

.font-purple-100 {
  color: #5807b8;
}

.font-black-pb {
  color: var(--pb-black);
}

.font-gray {
  color: var(--light-gray);
}

.font-purple {
  color: #30125c;
}

.light-purple {
  background: #c3a2f5;
}

.aqua-green {
  background: var(--aqua-green);
}

.bg-gray {
  background: var(--gray);
}

.dark-purple {
  background: var(--dark-purple);
}

.border-gray {
  border-color: var(--light-gray);
}

.border-aqua-green {
  border-color: var(--aqua-green);
}

.border-dark-purple {
  border-color: var(--dark-purple);
}

.font-aqua-green {
  color: var(--aqua-green);
}

.font-dark-purple {
  color: var(--dark-purple);
}

.hover-aqua-green:hover {
  color: var(--aqua-green);
  cursor: pointer;
}

.hover-dark-purple:hover {
  color: var(--dark-purple);
  cursor: pointer;
}

.light-aqua-green {
  background: var(--light-aqua-green);
}

.light-purple-2 {
  background: #fbf7ff;
}

.font-light-purple {
  color: var(--light-purple);
}

.font-green {
  color: #14c4be;
}

.font-light-gray {
  color: var(--light-gray);
}

.font-dark-gray {
  color: var(--dark-gray);
}

.borderGradient {
  border: 2px dashed #ffffff;
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(to left, #707070 29.29%, rgba(213, 213, 213, 0.87) 75.28%) border-box;
}

.borderGradient-no-bg {
  border: 2px dashed #fff;
  background: linear-gradient(#f7f7fa, #f7f7fa) padding-box,
    linear-gradient(to left, #707070 29.29%, rgba(213, 213, 213, 0.87) 75.28%) border-box;
}
.borderGradient-light-gray {
  border: 2px dashed #d4d4d4;
}

.Toastify__toast--info {
  background: #302c78 !important;
}

/* Altura da lista de cards do carrossel */
.slick-list {
  padding: 5px 0 !important;
}

/* Torna a lista em linha  */
.slick-track {
  display: flex !important;
}

.slick-slide {
  margin-right: 10px;
}

.filter-active {
  box-shadow: 0 0 3px 0px purple;
}

.button-disabled {
  cursor: not-allowed;
  opacity: 0.22;
}

img {
  object-fit: cover;
}

.slick-slider {
  width: 100%;
}

.section__title {
  font-weight: 800;
  font-size: 16px;
  color: #000000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
