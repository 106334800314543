.wrapper {
  height: calc(100dvh - 140px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.wrapper > div {
  width: 100%;
}

.content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 800;
  font-size: 20px;
  margin-top: 20px;
}

.wrapperListen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.text {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.translation {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
}

.slowButtonWrapper {
  display: flex;
  gap: 16px;
}

.slowButton {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: #8d5dc6;
  box-shadow: 0px 5.8px 0px #6a51b2;
  border-radius: 50%;
}

.button {
  width: 81px;
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8d5dc6;
  box-shadow: 0px 5.8px 0px #6a51b2;
  border-radius: 50%;
}

.wrapperButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  justify-content: center;
}

.wrapperButton > div {
  text-align: center;
}

.button svg {
  width: 50px;
  height: 50px;
}

.slowButton svg {
  width: 28px;
  height: 28px;
  fill: white;
  stroke: white;
}

.wrapperImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.image {
  width: 100%;
  height: 120px;
  object-fit: contain;
}
