.modal-banner-premium {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.modal-banner-premium-button-wrapper {
  width: 300px;
}

.modal-banner-premium-button-outline {
  width: 100%;
  height: 47px;
  font-weight: 400;
  font-size: 20px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #723fb1;
  border: 1.5px solid #723fb1;
  gap: 10px;
  background: #ffffff;
  border-radius: 16px;
}

.modal-banner-premium-button-primary {
  width: 100%;
  height: 47px;
  padding: 15px 10px;
  font-weight: 800;
  font-size: 20px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6b09df;
  gap: 10px;
  color: #ffffff;
  border-radius: 16px;
  margin-top: 10px;
}

.modal-banner-premium-text-wrapper {
  display: flex;
  flex-direction: column;
}

.modal-banner-premium-title > span {
  font-weight: bold;
  color: #723fb1;
}

.modal-banner-premium-title {
  width: 200px;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.modal-banner-premium-description {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 16px;

  color: black;
  margin-bottom: 10px;
}

@media (max-width: 425px) {
  .modal-banner-premium-text-wrapper {
    width: 100%;
  }
}
