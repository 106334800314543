.progress__container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
  width: 100%;
}

.progress__bar {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100px;
  width: 100%;
  height: 8px;
}

.progress__fill {
  border-radius: 100px;
  width: 0%;
  height: 100%;
  background-color: #FFF;
  transition: width 600ms ease;
  flex: 1;
}

.progress__details {
  font-size: 10px;
  font-weight: 600;
  color: #D1E13C
}

.progress__percentage {
  font-size: 12px;
  font-weight: 800;
  text-align: right;
  color:#D1E13C;
}

@media (min-width: 768px) {
  .progress__details {
    font-size: 15px;
  }
  
  .progress__percentage {
    font-size: 18px
  }
}