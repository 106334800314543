.secondary-category-card {
  display: flex;
  flex-direction: column;
  background-color: #6A51B2;
  padding: 20px 20px 0;
  border-radius: 8px;
  width: 100%;
  max-width: 450px;
}

.secondary-category-card .progress__container {
  margin-bottom: 10px;
}

.secondary__main {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.secondary__main > div {
  width: 100%;
}

.secondary-category__image {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 8px;
}

.secondary-category__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 15px;
}

.secondary-category__title {
  font-weight: 800;
  font-size: 16px;
  color: #FFFFFF;
}

.secondary-category__level {
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  text-transform: lowercase;
  opacity: 0.5;
}

.secondary-category__description {
  font-weight: 500;
  line-height: 20px;
  display: block;
  font-size: 15px;
  color: #FFFFFF;
  opacity: 0.6;

  margin-top: 17px;
}

.secondary-category-card > button {
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  padding: 15px;
  margin-top: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
