#app {
  margin-top: 40px;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #f2ecf8;
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-image {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 115px;
  height: 115px;
}

.progressCircularWrapper {
  position: relative;
}
