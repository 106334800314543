.banner-teacher {
  position: relative;
  min-height: 150px;
  background-color: #23c1c9;
  border-radius: 8px;
  padding: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  cursor: pointer;
}

.banner-teacher > span {
  display: block;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.2px;
  color: #fff;
  margin-bottom: 20px;
  width: 50%;
  line-height: 25px;
}

.banner-teacher > span > span {
  font-weight: 800;
  color: #fdc93a;
}

.banner-teacher > button {
  font-weight: 800;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: #fdc93a;
  gap: 10px;
}
