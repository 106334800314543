.Backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
}

.Container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px;
  max-width: 500px;
  margin: 0 auto;
}

.Wrapper {
  width: 100%;
  background-color: white;
  border-radius: 0 0 19px 19px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Image {
  width: 100%;
  height: 280px;
  border-radius: 19px 19px 0 0;
}

.ButtonPrimary {
  width: 100%;
  height: 60px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #723fb1;
  color: #ffffff;
  border-radius: 16px;
  font-size: 18px;
}

.WrapperButtonClose {
  position: absolute;
  top: 10px;
  right: 12px;
}

.ButtonClose {
  background-color: white;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.Title {
  line-height: 29px;
  font-weight: bold;
  color: #424242;
  text-align: center;
  font-size: 24px;
}

.Title > p {
  color: #58a700;
}

.Description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  color: #666666;
  margin-bottom: 24px;
  margin: 24px auto;
}
