.plans__section {
  max-width: 1100px;
  padding: 20px;
  margin-bottom: 100px;
  margin: 0 auto;
}

.plans {
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid #e4e4e7;
}

.plans__bg {
  position: fixed;
  inset: 0;
  height: 100dvh;
  overflow: auto;
  z-index: 999;
  background: rgba(19, 19, 20, 0.5);
}

.section__title {
  font-family: "Urbanist", sans-serif;
  font-weight: 700;
  color: #33303e;
}

.section__subtitle {
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
  color: #33303e;
}

.plans__options {
  width: 100%;
  background-color: #fff;
  padding: 20px;
}

.options__title {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.25px;
  color: #292a2c;
  display: block;
}

.options__legend {
  width: 80%;
  font-weight: 600;
  font-size: 16px;
  color: #7a7786;
  display: block;
  margin-top: 20px;
}

.plan__label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}

.plan__label > input {
  appearance: none;
  min-width: 24px;
  min-height: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 100px;
}

.plan__label > input:checked {
  background-color: #6a51b2;
  border: 1px solid #6a51b2;
  position: relative;
}

.plan__label > input:checked::after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  position: absolute;
  inset: 0;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  top: 5px;
  left: 8px;
}

.btn-close--mobile {
  display: flex;
}

.btn-close--desktop {
  display: none;
}

.plan-item {
  border: 1px solid #d9d9d9;
  border-bottom-width: 6px;
}

.plan-item-active {
  border-color: #6a51b2;
}

.feedback-item {
  max-width: 248px;
  height: 220px;
  padding: 20px;
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(140, 138, 151, 0.1);
  border-radius: 10px;
}

.feedback-item > p {
  font-weight: 500;
  font-size: 14px;
  color: #292a2c;
}

.btn-choose-plan {
  background: #623cea;
  border: 1px solid #623cea;
  border-bottom-width: 6px;
  border-bottom-color: #4e2ec3;
  border-radius: 8px;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  width: 100%;
  padding: 10px;
}

@media screen and (min-width: 1024px) {
  .plans__section {
    padding: 30px;
  }

  .btn-close--desktop {
    display: flex;
  }

  .btn-close--mobile {
    display: none;
  }

  .plans__container {
    display: flex;
    justify-items: space-between;
    margin-top: 20px;
    gap: 20px;
  }

  .plans__bg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .plans__options {
    max-width: 800px;
    max-height: 680px;
    display: flex;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
  }

  .plans__options > div {
    width: 50%;
  }

  .left-column {
    background: #f9f9fb;
    padding: 30px;
  }

  .left-column ul {
    font-weight: 500;
    font-size: 18px;
    color: #2f2f2f;
    display: block;
    margin-top: 30px;
  }

  .left-column ul li {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .right-column {
    padding: 25px;
    display: flex;
    gap: 50px;
    flex-direction: column;
  }
}
