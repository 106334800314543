.accountCreatedSuccess h6 {
  color: #383743;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4em;
  margin-bottom: 26px;
}

.accountCreatedSuccess button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #5807b8;
  border-radius: 8px;
  height: 52px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8em;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: 24px;
}
