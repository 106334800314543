.Backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.Wrapper {
  width: 100%;
  height: 100%;
  max-width: 564px;
  background-color: white;
  border-radius: 19px 0 0 19px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.WrapperStoreButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.Image {
  width: 100%;
  height: 600px;
}

.WrapperButtonClose {
  position: absolute;
  top: 10px;
  right: 12px;
}

.ButtonClose {
  background-color: white;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.WrapperContent {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
}

.Title {
  line-height: 39px;
  font-weight: 800;
  color: #292a2c;
  text-align: center;
  font-size: 36px;
}

.Description {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
  color: #767676;
  margin-bottom: 24px;
}

.WrapperCursiveLine {
  display: block;
  position: absolute;
  bottom: -38px;
}

@media (max-width: 1024px) {
  .Title {
    font-size: 32px;
  }

  .WrapperCursiveLine {
    display: none;
  }
}
