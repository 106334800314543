.wrapper {
  height: calc(100dvh - 140px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 800;
  font-size: 20px;
  margin-top: 24px;
}

.question {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wrapper > div {
  width: 100%;
}

.wrapper > div:nth-of-type(2) {
  overflow-y: auto;
}

.wrapperButton {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.wrapperImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.image {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

.correctionBox {
  padding: 20px;
}

.correctionButton {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

@media (min-height: 700px) {
  .image {
    height: 90px;
  }
}
