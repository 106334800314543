.input {
  position: relative;
}

.inputLabel {
  position: absolute;
  top: 17px;
  left: 10px;
  font-size: 15px;
  transition: 0.5s;
  pointer-events: none;
}

.input input {
  width: 100%;
  height: 56px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.input input:focus ~ .inputLabel,
.input input:valid ~ .inputLabel {
  color: #7c7c7c;
  font-size: 10px;
  top: 10px;
}

.showHide {
  position: absolute;
  top: 17px;
  right: 16px;
  cursor: pointer;
}
