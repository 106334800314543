.wrapperRect {
  min-height: 150px;
  border-radius: 8px;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
  align-items: flex-end;
}

.title {
  text-align: center;
  color: white;

  font-weight: 900;
  font-size: 19.2296px;
  line-height: 21px;
}

.title:first-child {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #ffd300;
}

.button {
  font-weight: 800;
  font-size: 12px;
  line-height: 8px;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}

@media (max-width: 425px) {
  .title {
    width: 170px;
    line-height: 22px;
  }

  .button {
    font-size: 14px;
  }

  .wrapperRect {
    padding: 15px;
  }

  .textWrapper {
    margin-right: 5px;
  }
}
