.teacherSchedule-bg {
  background: url("../../../../assets/imgs/Points.png");
  background-color: var(--light-purple);
  height: 10em;
  background-position: right;
  border-radius: 0px 0px 45px 0px;
}

.squared-filled-green {
  width: 20px;
  height: 20px;
  background: #14c4be;
}

.squared-filled-purple {
  width: 20px;
  height: 20px;
  background: #5807b8;
}

.squared {
  width: 20px;
  height: 20px;
  background: #ffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.schedule-grid {
  display: grid;
  grid-template-columns: 100px repeat(7, 1fr);
  column-gap: 10px;
}

.pill-shape {
  border-radius: 50px 50px;
  height: 35px;
  border: 1px solid #dcdcdc;
  cursor: pointer;
}

.pill-shape-available {
  text-align: center;
  background: #14c4be;
  color: #ffff;
}

.pill-shape-schedule {
  text-align: center;
  background: #5807b8;
  color: #ffff;
}
