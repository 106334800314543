.slider-container {
  max-width: calc(100vw - 200px);
  width: calc(100vw - 200px);
  overflow: hidden;
  padding: 1em;
}

.borderBehind {
  border-radius: 24px;
  background-color: #14c4be;
}

.profileNameColor {
  color: #14c4be;
}

.hourCard {
  background-color: #f0f0f0;
}

.phillShape {
  min-width: 82px !important;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid rgba(142, 142, 142, 0.3);
  cursor: pointer;
}

.phillShape-active {
  background-color: #14c4be;
  color: #fff;
}

.textColor {
  color: #14c4be;
}

.button-action {
  border-radius: 100px;
  font-size: 0.9em;
  font-weight: 600;
  padding: 7px 20px;
}

.next-week {
  background-color: var(--light-purple);
  color: #5c22b6;
  padding-right: 35px;
}

.next-week:active,
.previous-week-active:active {
  background-color: #b892f1;
}

.previous-week {
  background-color: #f0f0f0;
  color: #999;
  padding-left: 35px;
}

.previous-week-active {
  background-color: var(--light-purple);
  color: #5c22b6;
  padding-left: 35px;
}
