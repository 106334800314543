.cardHeader {
  border-radius: 16px;
  border-left: 3px solid rgba(195, 162, 245, 1);
}

.classesBg {
  background-color: rgba(195, 162, 245, 1);
  border-radius: 500px;
}

.btnFilter {
  color: white;
  background: #5807b8;
}

.my-teachers-remaining-classes {
  grid-column: span 3 / span 3;
  grid-column-start: 4;
}

@media (min-width: 1281px) {
  /* .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1200px;
    margin-right: 25em;
  } */

  .my-teachers-remaining-classes {
    grid-column: span 3 / span 3;
    grid-column-start: 7;
  }
  .myTeachers-bg {
    background: url("../../../../assets/imgs/Points.png");
    background-color: #5708b6;
    height: 10em;
    background-position: right;
    border-radius: 0px 0px 45px 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .myTeachers-bg {
    background: url("../../../../assets/imgs/Points.png");
    background-color: #5708b6;
    height: 13em;
    background-position: right;
    border-radius: 0px 0px 45px 0px;
  }

  .my-teachers-remaining-classes {
    grid-column: span 6 / span 4;
    grid-column-start: 8;
    margin-top: 2.5rem;
    margin-right: 0.4em;
  }
}

@media (min-width: 300px) {
  .hiddenInMobile {
    display: none;
  }
}

@media (min-width: 700px) {
  .hiddenInMobile {
    display: contents;
  }
}
