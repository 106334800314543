.banner_premium_square {
  width: 236px;
  position: relative;
  background: #f36a8d;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.banner_premium_square > div {
  padding: 10px;
}

.banner_premium_square > div > p:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: white;
}

.banner_premium_square > div > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: white;
}

.banner_premium_square > div > p:nth-child(2) {
  color: #6a51b2;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
}

.banner_premium_square > span > span {
  font-weight: 800;
  color: #fdc93a;
}

.banner_premium_square > button {
  font-weight: 800;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: white;
  gap: 10px;
}

.banner_premium_square > img {
  width: 100%;
  height: 173px;
}

.div_premium {
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 37px;
  background-color: #6a51b2;
  border-radius: 0px 0px 8px 8px;
}

.div_premium > p {
  font-weight: 800;
}
