.checkout__section {
  padding: 20px;
  height: 100%;
  max-width: 1000px;
  background-color: white;
  margin: 0 auto;
  border-radius: 15px;
  border: 1px solid #e4e4e7;
}

.warning--desktop {
  display: none;
}

.warning--mobile {
  margin-bottom: 50px;
}

.btn-checkout {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  min-width: 192px;
  margin-bottom: 80px;
}

.switcher__container > div {
  margin-bottom: 50px;
}

.credit-card__container form {
  margin-top: 50px;
}

.price__container {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.8);
}

.price__container div {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.price__container div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.price__container span {
  color: #6a51b2;
  font-size: 24px;
}

.price__container div span {
  font-size: 16px;
}

.price__container .price--total,
.price__container .price--total span {
  font-size: 24px;
}

.coupon-form input {
  height: 40px;
  text-transform: uppercase;
}

.form-row {
  flex-direction: column;
}

@media screen and (min-width: 1024px) {
  .checkout__section {
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .form-row {
    flex-direction: row;
  }

  .input-small {
    width: 40% !important;
  }

  .btn-checkout {
    max-width: 420px;
    margin-bottom: 0;
  }

  .switcher__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switcher__container > div {
    width: 30%;
    min-width: 380px;
    margin-bottom: 0;
  }

  .warning--desktop {
    display: block;
    max-width: 520px;
    margin: 70px auto;
  }

  .credit-card__container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .credit-card__container form {
    width: 100%;
    max-width: 500px;
    margin-top: 0;
  }

  .warning--mobile {
    display: none;
  }

  .discount__container {
    max-width: 500px;
  }

  .price__container {
    max-width: 500px;
  }
}
