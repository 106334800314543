.test-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto 0;
  padding: 30px;
  width: 100%;
}

.test-wrapper__question {
  max-width: 800px;
  width: 100%;
}

.question__title {
  display: block;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 50px;
  text-align: center;
}

.answers__item {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  box-shadow: 0px 3px 0px #DDDDDD;
  cursor: pointer;
  display: block;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 20px;
  text-align: left;
  width: 100%;
}

.answers__item:enabled:hover {
  background-color: #F8F8F8;
}

.question__answers .answer--correct {
  background-color: #D7FFB8;
  box-shadow: 0px 3px 0px #58A700;
  border: 1px solid #58A700;
  color: #58A700;
}

.question__answers .answer--incorrect {
  background-color: #FFDFE0;
  box-shadow: 0px 3px 0px #EA2B2B;
  border: 1px solid #EA2B2B;
  color: #EA2B2B;
}

.feedback {
  align-items: center;
  border-top: 1px solid #DDDDDD;
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.bg-correct {
  background-color: #D7FFB8;
}

.bg-incorrect {
  background-color: #FFDFE0;
}

.feedback .response-correct {
  color: #58A700;
  font-size: 1.5rem;
  font-weight: 800;
}

.feedback .response-incorrect {
  color: #EA2B2B;
  font-size: 1.5rem;
  font-weight: 800;
}

.feedback .button-next {
  background-color: #F4F4F4;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  box-shadow: 0px 3px 0px #DDDDDD;
  color: rgba(54, 52, 75, 0.21);
  font-size: 1.1rem;
  font-weight: 700;
  padding: 12px 40px;
}

.feedback .button-correct {
  background-color: #58CC02;
  border: none;
  border-bottom: 4px solid #58A700;
  box-shadow: none;
  color: white;
}

.feedback .button-incorrect {
  background-color: #FF4B4B;
  border: none;
  border-bottom: 4px solid #EA2B2B;
  box-shadow: none;
  color: white;
}

.button-correct:active {
  border-top: 4px solid #58A700;
  border-bottom: none;
}

.button-incorrect:active {
  border-top: 4px solid #EA2B2B;
  border-bottom: none;
}

.result-wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-wrapper span {
  display: block;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
}

.result-wrapper .btn-start {
  background-color: #623CEA;
  border: 1px solid #482daa;
  border-radius: 10px;
  box-shadow: 0px 3px 0px #482daa;
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 12px 40px;
}

.btn-start:hover {
  background-color: #6941fa;
}
