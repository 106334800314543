.banner-conversation {
  width: 236px;
  padding: 20px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
}

.banner-conversation > div > p {
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: #3f97cf;
  display: inline-block;
}

.banner-conversation > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-conversation p > span {
  color: #9a71c6;
  font-weight: 700;
}

.banner-conversation button {
  font-weight: 800;
  font-size: 12px;
  line-height: 8px;
  text-transform: uppercase;
  color: #3f97cf;
}

.banner-conversation img {
  border-radius: 8px;
  width: 100%;
  height: 60%;
}
