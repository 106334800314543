.plan-card--small {
  display: flex;
  align-items: center;
  gap: 15px;
}

.plan-card--small > span {
  width: 50px;
  height: 50px;
  display: block;
  background-position: center;
  background-size: cover;
}

.plan__title--small {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  display: block;
  line-height: 20px;
}

.plan__description--small {
  font-weight: 500;
  font-size: 14px;
  color: #858585;
  display: block;
  line-height: 18px;
}

.plan__price--small {
  font-weight: 800;
  font-size: 20px;
  color: #4e2ec3;
  display: block;
}
