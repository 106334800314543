.minhas-aulas-jumbotron {
  background: linear-gradient(89.91deg, #ffc300 0.07%, #f59300 99.92%);
  border-radius: 0px 0px 45px 0px;
  height: 18em;
}

.minhas-aulas-jumbotron-card {
  background: #fcc226;
  border-radius: 8px;
  height: 5.5em;
  max-width: 638px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
}

.vertical-line {
  border-left: 5px solid #ffffff;
  border-radius: 0px 5px 5px 0px;
  height: 3.5em;
}

.btnPayment {
  background: #c3a2f5;
  color: #5807b8;
}

.myplans-jumbotron-img {
  max-height: 18em;
}

.myplans-card-btn {
  background-color: #ffe799;
  color: #bb5c00;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s;
}

.myplans-open-link {
  color: white;
  text-decoration: underline;
  font-weight: 600;
}

.myplans-card-btn:hover {
  background-color: #ffc300;
  border: 1px solid #ffe799;
  color: #ffffff;
}
@media (max-width: 1500px) {
  .minhas-aulas-jumbotron-card-text {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .needHelp {
    margin-top: 1rem;
  }
}

@media (min-width: 1281px) {
  .needHelp {
    margin-top: 0;
    margin-left: 1rem;
  }

  .myplans-card-btn {
    background-color: #ffe799;
    color: #bb5c00;
  }
}
