.generic-select__label {
  font-weight: 500;
  font-size: 16px;
  width: 100%;
}

.generic-select {
  font-weight: 500;
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 15px;
  height: 56px;
  background: #F3F3F3;
  border-radius: 8px;
}

.generic-select:invalid {
  color: #828282;
}
