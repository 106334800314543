.header__dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 20px;
  position: relative;
}

.dropdown__image {
  width: 35.75px;
  height: 40px;
  background-size: cover;
  background-position: center center;
  clip-path: path('M15.8097 0.553008C17.0868 -0.184336 18.6602 -0.184337 19.9374 0.553008L33.6832 8.48915C34.9603 9.2265 35.747 10.5892 35.747 12.0639L35.747 27.9361C35.747 29.4108 34.9603 30.7735 33.6832 31.5108L19.9374 39.447C18.6602 40.1843 17.0868 40.1843 15.8097 39.447L2.06386 31.5108C0.786738 30.7735 5.49958e-07 29.4108 5.22382e-07 27.9361L2.25584e-07 12.0639C1.98008e-07 10.5892 0.786737 9.2265 2.06385 8.48915L15.8097 0.553008Z');
}

.dropdown__username {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.dropdown__chevron-down {
  font-size: 25px;
  transition: transform 200ms ease;
}

.chevron-up {
  transform: rotate(180deg);
}

.dropdown__options {
  position: absolute;
  background-color: #FFF;
  width: 208px;
  height: 160px;
  inset: 0;
  margin: 70px 0 0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  z-index: 9;
}

.dropdown__options::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  transform: translate(0, -80%);
  border: 15px solid #FFF;
  border-color: transparent transparent #FFF;
}

.dropdown__options a {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
  display: block;
  margin-bottom: 15px;
  padding: 5px 10px;
  border-radius: 8px;
}

.dropdown__options a:hover {
  background-color: #F1F1F1;
}
