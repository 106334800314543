.index__container {
  padding: 10px 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 60px;
}

.container__progress {
  max-width: 800px;
  margin: 0 auto;
}

.welcome__message {
  width: 100%;
}

.index__content {
  grid-area: content;
}

.container__banners {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 30px 0;
}

.container__banners > div {
  width: calc(50% - 5px);
  min-width: 500px;
}

.dotted-divider {
  margin-left: 55px;
}

.promotion_banner {
  position: fixed;
  width: 100px;
  height: 150px;
  border-radius: 20px;
  background-color: #733eb1;
  color: white;
  top: 10%;
  right: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.goBack_banner {
  position: fixed;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  background-color: #733eb1;
  color: white;
  bottom: 5%;
  right: 53%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
  cursor: pointer;
}

.promotion_banner > p {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.welcome__description {
  font-size: 18px;
  font-weight: 600;
  color: #767676;
  margin: 10px 0px;
}

@media screen and (max-width: 1440px) {
  .index__sidebar {
    display: none;
  }

  .promotion_banner {
    right: 10px;
  }

  .goBack_banner {
    right: 50%;
  }
}

@media screen and (max-width: 505px) {
  .promotion_banner {
    right: 0;
  }

  .goBack_banner {
    bottom: 10px;
    right: 40%;
    z-index: 1000;
  }
}
