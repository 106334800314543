.ProgressBar {
  width: 100%;
  background-color: #dbddde;
  height: 12px;
  border-radius: 99px;
  position: relative;
}

.Progress {
  height: 12px;
  border-radius: 99px;
  position: relative;
}

.Completed {
  background-color: #27ae60;
  border-radius: 99px;
}

.ProgressText {
  position: absolute;
  top: -8px; /* ajuste conforme necessário para a posição vertical */
  background-color: #27ae60;
  font-weight: bold;
  color: white;
  padding: 5px 10px;
  border-radius: 99px;
  font-size: 12px;
}
