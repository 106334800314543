.switcher {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 100px;
  border: 6px solid #f2f1f6;
  margin-top: 15px;
}

.switcher > button {
  width: 50%;
  padding: 7px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #acaac5;
}

.switcher .button--active {
  background: #4e2ec3;
  color: #fff;
  font-weight: 800;
}
