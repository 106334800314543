.wrapper {
  display: flex;
  align-items: center;
}

.button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8d5dc6;
  box-shadow: 0px 2.38775px 0px #6a51b2;
  border-radius: 50%;
  z-index: 12;
}

.text {
  color: #5b5b5b;
  font-weight: 500;
  font-size: 16px;
  background: #f2ecf8;
  line-height: 24px;
  padding: 8px;
  padding-left: 40px;
  margin-left: -30px;
  border-radius: 20px 10px 10px 20px;
}
