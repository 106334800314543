.wrapper {
  height: calc(100dvh - 140px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.wrapper > div {
  overflow-y: auto;
  width: 100%;
}

.html {
  margin-top: 10px;
  max-height: 530px;
}

.html img {
  max-width: 100%;
}

.title {
  font-weight: 800;
  font-size: 20px;
  margin-top: 20px;
}

@media screen and (min-width: 1024px) {
  .html img {
    max-width: 450px;
  }
}

@media screen and (min-width: 1920px) {
  .html img {
    max-width: 700px;
  }
}
