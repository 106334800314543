.wrapper {
  height: calc(100dvh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.wrapper > div {
  width: 100%;
}

.title {
  font-weight: 800;
  font-size: 20px;
  margin-top: 50px;
}

.wrapperQuestions {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.question {
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 10px;
}

.wrapperButton {
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.errorFeedback {
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
}

.wrapperImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.image {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

@media (max-height: 700px) {
  .wrapperQuestions {
    justify-content: flex-start;
    margin-top: 15px;
  }

  .image {
    height: 90px;
  }
}

@media (max-height: 700px) {
  .wrapperQuestions {
    justify-content: flex-start;
    margin-top: 15px;
  }

  .image {
    height: 90px;
  }

  .questions {
    gap: 8px;
    margin-bottom: 0px;
  }
}
