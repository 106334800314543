.banner_conversation_rect {
  position: relative;
  min-height: 150px;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.banner_conversation_rect > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner_conversation_rect > img {
  width: 80%;
  height: 90px;
  border-radius: 8px;
}

.banner_conversation_rect > div > p {
  display: block;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.2px;
  color: #3f97cf;
  line-height: 25px;
}

.banner_conversation_rect div > p > span {
  font-weight: 800;
  color: #9a71c6;
}

.banner_conversation_rect > div > button {
  width: 250px;
  font-weight: 800;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: #3f97cf;
  gap: 10px;
}

@media screen and (max-width: 425px) {
  .banner_conversation_rect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 200px;
    padding: 15px;
  }

  .banner_conversation_rect > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .banner_conversation_rect > div > button {
    font-size: 10px;
    margin-top: 8px;
    justify-content: center;
  }

  .banner_conversation_rect > div > p {
    text-align: center;
    font-size: 16px;
    line-height: 1;
  }

  .banner_conversation_rect > img {
    margin-top: 10px;
    width: 100%;
    height: 100px;
  }
}

.banner_conversation_rect::after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 6px;
  background-color: #6a51b2;
  border-radius: 0px 0px 8px 8px;
}
