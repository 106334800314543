.BotResponse {
  word-wrap: break-word;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 17px;
  white-space: pre-wrap;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  flex-shrink: 0;
  border-bottom-left-radius: 5px;
}

.QuickReplies {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
  gap: 10px;
}

.QuickReplies button {
  padding: 0 16px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  height: 32px;
  border-width: 1px;
  border-color: #733eb1;
  color: #733eb1;
  background: rgb(255, 255, 255);
  font-weight: bold;
}
