.plan-card {
  max-width: 550px;
  background-color: #fbfcfd;
  padding: 15px;
  border-radius: 22px;
  flex: 1;
  border: 1px solid #e4e4e7;
}

.plan-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 30px;
  border-bottom: 1px solid rgba(159, 159, 159, 0.1);
  margin-bottom: 20px;
  height: 94px;
}

.plan-card__header > div {
  align-items: center;
}

.plan__image {
  width: 40px;
  height: 40px;
  border-radius: 200px;
  display: block;
  background-size: cover;
  background-position: center;
  margin-right: 10px;
}

.plan-card__title {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  line-height: 20px;
}

.plan-card__price {
  font-weight: 700;
  font-size: 28px;
  color: #623cea;
  font-family: "Inter", sans-serif;
}

.plan-card__sifra {
  font-size: 28px;
}

.plan_price--legend {
  display: block;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  opacity: 0.5;
  font-style: normal;
  margin-bottom: -10px;
  text-align: right;
}

.plan_price--label {
  font-size: 14px;
  text-align: right;
  font-weight: 500;
  color: #33303e;
  font-family: "Urbanist", sans-serif;
}

.plan-card ul {
  font-weight: 500;
  font-size: 18px;
  color: #2f2f2f;
}

.plan-card ul li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.btn-choose-plan:disabled {
  background: #edeaf6;
  color: rgba(115, 62, 177, 0.4);
  font-weight: 600;
  cursor: default;
}

.btn-choose-plan:hover {
  background-color: #4e30bb;
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.switcher-plan {
  max-width: 210px;
  background-color: #f9fafb;
  padding: 4px;
  border-radius: 100px;
  border: 2px solid #eaecf0;
}

.switcher-plan > button {
  padding: 8px 12px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 600;
  color: #344054;
  line-height: 20px;
}

.switcher-plan .button--active {
  background: #6a51b2;
  color: #fff;
}

.switcher-plan .button--active--first {
  background-color: #ffffff;
  color: rgba(52, 64, 84, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.fromPrice {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  color: #9f9f9f;
  text-align: right;
}
