.event-participants {
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-participants__image {
  min-width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid white;
}

.margin {
  margin-left: -8px;
}

.event-participants__quantity {
  min-width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid white;
  margin-left: -8px;
  font-weight: 800;
  font-size: 10px;
  letter-spacing: -0.5px;
  background-color: #6AAFDD;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px 0 0;
}
