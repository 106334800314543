.Backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.Container {
  max-width: 450px;
  width: 100%;
  height: 700px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideDown {
  animation: slideDown 0.5s ease-out;
}

@media screen and (max-width: 505px) {
  .Container {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
    overflow-y: scroll;
  }

  .Backdrop {
    padding: 0;
  }
}
