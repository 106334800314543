.remainingClasses {
  border-radius: 16px;
  height: 80px;
  width: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bgExpiredPlan {
  background-color: #fbf7ff;
  border-radius: 16px;
  height: 80px;
  width: 350px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.borderExpiredPlanRed {
  position: absolute;
  height: 40px;
  left: -1px;
  border: 2px solid #ffbfb2;
}

.borderExpiredPlanPurple {
  position: absolute;
  height: 40px;
  left: -1px;
  border: 2px solid rgb(195, 162, 245, 1);
  border-radius: 0px 5px 5px 0px;
}

.textExpiredPlan {
  color: rgb(209, 4, 4);
}

.bgPlaySVG {
  background-color: #ffbfb2;
}

@media (min-width: 640px) {
  .bgExpiredPlan {
    background-color: #fbf7ff;
    border-radius: 16px;
    height: 80px;
    width: 400px;
  }

  .remainingClasses {
    border-radius: 16px;
    height: 80px;
    width: 400px;
  }
}
