.modal-banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 470px;
  border-radius: 8px;
}

.modal-banner-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.modal-banner-jumbotron {
  width: 459px;
  height: 100%;
}

.modal-banner-content {
  top: 0;
  right: 0;
  position: absolute;
  width: 500px;
  height: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
}

.modal-banner-close-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(43, 47, 77, 0.4);
  z-index: 9999999;
}

@media (max-width: 500px) {
  .modal-banner {
    width: 100vw;
    height: 100vh;
  }

  .modal-banner-jumbotron {
    width: 100%;
    height: 48vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px;
  }

  .modal-banner-content {
    position: absolute;
    width: 100%;
    height: 60vh;
    bottom: 0;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    top: unset;
  }

  .modal-banner-close-button {
    display: flex;
    justify-content: flex-start;
  }
}

@media (max-height: 667px) {
  .modal-banner-jumbotron {
    height: 62vh;
  }

  .modal-banner-content {
    height: 62vh;
  }
}
