@keyframes scrollTop {
  0% {
    bottom: -500px;
  }
  100% {
    bottom: 0;
  }
}

.animation {
  animation: scrollTop 1s;
  position: absolute;
  z-index: 10;
  overflow-y: hidden;
}

.box {
  width: 100%;
  bottom: 0;
  background-color: white;
  border-radius: 10px 10px 0 0;
}
