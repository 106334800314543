.Message {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 25px 10px;
  position: relative;
  display: flex;
  flex-shrink: 0;
}
