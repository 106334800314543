.tertiary-category-card {
  display: flex;
  flex-direction: column;
  background-color: #fbfcfd;
  padding: 20px 20px 0;
  border-radius: 8px;
  width: 100%;
  max-width: 450px;
  transition: 0.1s ease-in-out background-color;
}

.tertiary-category-card:hover {
  background-color: #f0f0f0;
}

.tertiary-category-card .progress__container {
  margin-bottom: 10px;
}

.tertiary__main {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.tertiary__main > div {
  width: 100%;
}

.tertiary-category__image {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 8px;
}

.tertiary-category__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 15px;
}

.tertiary-category__title {
  font-weight: 800;
  font-size: 16px;
  color: #ffffff;
}

.tertiary-category__level {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  text-transform: lowercase;
  opacity: 0.5;
}

.tertiary-category__description {
  font-weight: 700;
  line-height: 20px;
  display: block;
  font-size: 14px;
  color: #292a2c;
  opacity: 0.6;
  margin-bottom: 8px;

  height: 100px;
}

.tertiary-category-card > button {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  padding: 15px;
  margin-top: auto;
  border-top: 1px solid rgba(141, 141, 141, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
