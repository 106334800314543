.lesson__container {
  margin-bottom: 100px;
}

.container__material {
  padding: 15px;
}

.container__video {
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 15px;

  z-index: 18;
}

.container__video__wrapper {
  padding-bottom: 1px;
}

@media (max-width: 1024px) {
  .container__video__wrapper {
    width: 100%;
  }
}

.video__iframe {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px 10px 0 0;
}

.video__bottom-bar {
  width: 100%;
  background-color: #5807b8;
  border-radius: 0 0 10px 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: 15px;
}

.button--download {
  background-color: #6b09de;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 0.85em;
  line-height: 14px;
  gap: 10px;
  min-width: 150px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 5px;
  padding-left: 5px;
}

.legend {
  font-weight: 500;
  font-size: 0.85em;
  text-align: right;
}

.legend--next-lesson {
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 235px);
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-50px, 28px);
}

.btn-conclude {
  background-color: #ddceee;
  color: #6b09df;
  font-weight: 700;
  font-size: 16px;
  /* padding: 0 15px; */
  display: none;
  border-radius: 0 0 8px 0;
}

.btn-conclude--viewed {
  background-color: #14cebe;
  color: white;
}

.btn-conclude--viewed .conclude__ball {
  border: 1px solid white;
  background-color: white;
  position: relative;
}

.btn-conclude--viewed .conclude__ball::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 5px;
  border: 3px solid #27ae60;
  border-width: 0 0 3px 3px;
  inset: 0;
  transform: rotate(-45deg);
  margin: 3px 0 0 2px;
}

.conclude__ball {
  width: 15px;
  height: 15px;
  display: block;
  border: 1px solid #6b09df;
  border-radius: 10px;
}

.container__material iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

.lesson__container__material {
  width: 55%;
}

@media screen and (max-width: 1024px) {
  .lesson__container__material {
    width: 100%;
  }
}

.container-banner--desktop {
  display: none;
}

.container-banner--mobile {
  padding: 15px;
}

@media screen and (min-width: 1024px) {
  .lesson__container {
    padding: 30px;
    display: flex;
  }

  .container__video {
    width: 45%;
    max-width: 560px;
    border-radius: 8px 0 0 8px;
  }

  .container__video > div {
    position: -webkit-sticky;
    position: sticky;
    top: 15px;
  }

  .container__material {
    /* width: 50%; */
    background-color: white;
    border-radius: 0 8px 8px 0;
  }

  .next-icon {
    cursor: pointer;
  }

  .btn-conclude {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-left: 12px;
    width: 118px;
  }

  .video__bottom-bar {
    padding-right: 0;
  }

  .video__bottom-bar > div {
    margin-left: auto;
  }

  .legend--next-lesson {
    max-width: calc(100% - 350px);
    transform: translate(-165px, 28px);
  }

  .container-banner--mobile {
    display: none;
  }

  .container-banner--desktop {
    display: block;
  }
}

.container__material input {
  color: revert;
}
