.borderEvents {
  cursor: pointer;
  color: rgba(157, 150, 163, 1);
  border-bottom: 2px solid rgba(182, 182, 182, 0.63);
}

.selectedEvents {
  cursor: pointer;
  font-weight: 600;
  color: rgba(20, 196, 190, 1);
  border-bottom: 2px solid rgba(20, 196, 190, 1);
}
