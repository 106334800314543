.Backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.Wrapper {
  width: 1400px;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  position: relative;
  padding: 40px;
}

.BannerImage {
  width: 600px;
  height: 100%;
}

.Image {
  width: 200px;
  height: 200px;
}

.WrapperButtonClose {
  position: absolute;
  top: 10px;
  right: 12px;
}

.ButtonClose {
  background-color: white;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.WrapperContent {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  height: 100%;
}

.Title {
  font-weight: 800;
  color: black;
  text-align: left;
  font-size: 32px;
  margin-top: 20px;
}

.Title > span {
  color: rgba(114, 63, 177, 1);
}

.Description {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
  color: #767676;
  margin-bottom: 24px;
}

.WrapperCursiveLine {
  display: block;
  position: absolute;
  bottom: -38px;
}

.Button {
  width: 65%;
  padding: 16px;
  border-radius: 12px;
  background-color: rgba(88, 167, 0, 1);
  color: white;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  text-transform: uppercase;
}

@media (max-width: 1224px) {
  .Title {
    font-size: 32px;
  }

  .WrapperCursiveLine {
    display: none;
  }

  .BannerImage {
    max-width: 430px;
    height: 100%;
    margin: 0 auto;
    height: 613px;
  }

  .WrapperContent {
    gap: 30px;
  }
}
