.myLessons-bg {
  background: url("../../../../assets/imgs/image-bg.png");
  background-color: #ffffff;
  border-radius: 0px 0px 45px 0px;
  height: 7em;
}

.jumbotron-text {
  width: 34em;
}

.vertical-line {
  border-left: 5px solid #ffffff;
  border-radius: 0px 5px 5px 0px;
  height: 2.5em;
}

.backgroundClasses {
  background-color: rgba(251, 247, 255, 1);
  border-radius: 16px;
  border-left: 2px solid rgba(195, 162, 245, 1);
  height: 45%;
}

.classesBackground {
  background-color: rgba(195, 162, 245, 1);
  border-radius: 500px;
}

@media (min-width: 640px) {
  .myLessons-bg {
    background: url("../../../../assets/imgs/image-bg.png");
    background-color: #ffffff;
    border-radius: 0px 0px 45px 0px;
    height: 9em;
  }
}

@media (min-width: 1024px) {
  .myLessons-bg {
    height: 8em;
  }
}
