.question-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
}

.question-title {
  display: block;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 50px;
}

.answers {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  width: 100%;
}

.answer-card {
  align-items: center;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: 0px 3px 0px #dddddd;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 170px;
  min-height: 190px;
  padding: 30px;
  transition: transform 200ms ease;
  width: 100%;
}

.answer-title {
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  line-height: 20px;
}

.answer-card:hover {
  transform: translateY(-8px);
}

.btn-phone-next {
  color: white !important;
  width: 150px !important;
  margin-top: 7px;
}

.answer-card img {
  width: 70px;
  position: relative;
}

.recommended-flag-mobile {
  font-size: 13px;
  text-align: center;
  width: 100%;
  opacity: 1;
  z-index: 2;
  font-weight: 700;
  border-radius: 7px 7px 0px 0px;
  background-color: #edeaf6;
  color: #733eb1;
  margin-bottom: -46px;
}

.recommended-flag {
  font-size: 13px;
  text-align: center;
  width: 130px;
  opacity: 1;
  font-weight: 700;
  border-radius: 7px;
  background-color: #edeaf6;
  color: #733eb1;
  margin-top: -18px;
}

@media screen and (max-width: 576px) {
  .answers {
    padding: 30px;
  }

  .answer-card {
    flex-direction: row;
    gap: 30px;
    height: 80px;
    justify-content: flex-start;
    max-width: 100%;
    min-height: 0;
  }

  .question-card {
    justify-content: flex-start;
  }

  .answer-card img {
    width: 40px;
  }

  .question-title {
    margin-bottom: 20px;
    padding: 0 30px;
    text-align: center;
  }
}
