.certificate-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  padding-top: 45px;
}

.certificate__image {
  width: 75px;
  transform: translate(0, -50%);
  position: absolute;
  top: 0;
}

.certificate__title {
  font-weight: 700;
  font-size: 14px;
}

.certificate__description {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 15px;
  margin: 0 0 10px;
  padding: 15px;
  min-height: 75px;
}

.certificate-card button {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  border-radius: 0 0 10px 10px;
}

@media screen and (min-width: 1024px) {
  .certificate-card {
    padding-top: 15px;
  }

  .certificate__image {
    transform: translate(0, 0);
    position: relative;
  }

  .certificate__title {
    font-size: 20px;
  }

  .certificate__description {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 20px;
  }

  .certificate-card button {
    font-size: 16px;
  }
}
