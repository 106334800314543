.btnHover button:hover {
  background: #ffffff;
  transition: all 1s;
}

.listHover button {
  border-radius: 0.5em;
  cursor: pointer;
}

.listHover button:hover {
  background: #f0f0f0;
}
