.disabledPagination {
  color: #A5ADBA !important;
  fill: #A5ADBA !important;
  cursor: not-allowed !important;
}

.activePagination {
  color: #252426 !important;
  fill: #252426 !important;
}
