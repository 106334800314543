.WrapperDesktop {
  border-radius: 12px;
  height: 130px;
  cursor: pointer;
  background: url('../../../../assets/imgs/banner-7-dias-expirado-desktop.png');
  background-repeat: round;
  background-size: cover;
  padding: 30px;
  color: white;
}

.WrapperDesktop p {
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
}

.WrapperDesktop > button {
  font-weight: 700;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  color: #532D80;
  background-color: white;
  gap: 8px;
  padding: 3px 18px;
  border-radius: 100px;
  margin-top: 18px;
}

.TextGreen {
  color: #A8FE68;
}

.Wrapper {
  border-radius: 8px;
  height: 100px;
  cursor: pointer;
  background: url('../../../../assets/imgs/banner-7-dias-expirado.png');
  background-repeat: round;
  background-size: cover;
  padding: 15px;
}

.Wrapper p {
  color: #FFFF;
  font-weight: 800;
  font-size: 17px;
  max-width: 150px;
  line-height: 18px;
}

.TextWrapper button {
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  color: #532D80;
  background-color: white;
  gap: 4px;
  padding: 3px 11px;
  border-radius: 100px;
  margin-top: 10px;
}
