.changeRecoveryPassword h6 {
  color: #383743;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
}

.changeRecoveryPassword p {
  color: #66647a;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 22px;
}

.changeRecoveryPassword form button {
  display: block;
  width: 100%;
  background: #5807b8;
  border-radius: 8px;
  height: 52px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: 24px;
}
