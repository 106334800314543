.cardWrapper {
  position: absolute;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  border-radius: 10px 10px 0 0;
}

.cardFooter {
  padding: 10px;
  position: absolute;
  width: 100%;
  height: 80px;
  bottom: 139px;
}

.cardFooter > button {
  max-width: 100%;
}

.cardFooter > div {
  margin: 0 auto;
}

.cardFooter h1 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  color: white;
}

.wrapper {
  position: relative;
  padding: 50vh 0;
  overflow: hidden;
}

.iframe {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
}

@media (min-width: 768px) {
  .iframe {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  .wrapper {
    position: relative;
    padding: 49.5vh 0;
    overflow: hidden;
  }
}
