.wrapper {
  height: calc(100dvh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.wrapper > div {
  width: 100%;
}

.content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 800;
  font-size: 20px;
  margin-top: 20px;
}

.wrapperSpeaking {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 100px;
}

.speaking {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.UserSelectNone {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.UserSelectNone > p {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.wrapperButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 5px;
}

.wrapperButton {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 20px;
}

.wrapperButton > div {
  text-align: center;
}

.buttonSubmit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button {
  width: 81px;
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1cb0f6;
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 300ms;
}

.text {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4e4e4e;
}

.pulse {
  animation: pulse-blue 1.3s infinite;
}

.button svg {
  width: 50px;
  height: 50px;
}

.Iframe {
  width: 150px;
  height: 150px;
  margin: 10px;
  border-radius: 50%;
}

.Feedback {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 30px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    height: 90dvh;
    gap: 0;
  }

  .speaking {
    gap: 10px;
  }

  .Iframe {
    width: 130px;
    height: 130px;
  }
}
