.textArea {
  width: 100%;
  height: 130px;
  resize: none;
  background: #f9f9f9;
  border: 1px solid #e0e0e6;
  border-radius: 8px;
  padding: 16px;
}

.textArea::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #bbbbbb;
}

.correct {
  border: 1px solid #58cc02;
  color: #58cc02;
}

.incorrect {
  border: 1px solid #ff0000;
  color: #ff0000;
}

@media (max-height: 768px) {
  .textArea {
    height: 120px;
    margin-bottom: 10px;
  }
}
