.warningTextColorCancel {
  color: #f59300;
}

.cancelLessonCancel {
  color: #b81212;
  border: 1px solid #b81212;
}

.borderButtonsTop {
  border-top: 1px solid rgba(0, 0, 0, 0.055);
}
