.header {
  background-color: #f9f9f9;
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.header__profile {
  display: flex;
  gap: 10px;
  flex: 1;
}

.profile__welcome {
  display: block;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: #000;
  margin: 5px 0 -6px;
}

.profile__hexagono {
  position: relative;
}

.hexagono__image {
  width: 72px;
  height: 80px;
  background-size: cover;
  background-position: center center;
  clip-path: path(
    "M32.3867 5.38759C34.6597 4.07523 37.4603 4.07523 39.7333 5.38758L64.1986 19.5126C66.4717 20.825 67.872 23.2503 67.872 25.875L67.872 54.1251C67.872 56.7498 66.4717 59.1752 64.1986 60.4875L39.7333 74.6126C37.4603 75.9249 34.6597 75.9249 32.3867 74.6126L7.92138 60.4875C5.64831 59.1752 4.24805 56.7498 4.24805 54.1251L4.24805 25.875C4.24805 23.2503 5.64831 20.825 7.92137 19.5126L32.3867 5.38759Z"
  );
}

.hexagono__border {
  position: absolute;
  width: 72px;
  height: 80px;
  background: #f2ecf8;
  clip-path: path(
    "M31.9318 1.10602C34.4861 -0.368673 37.633 -0.368673 40.1872 1.10602L67.6788 16.9783C70.2331 18.453 71.8066 21.1783 71.8066 24.1277L71.8066 55.8723C71.8066 58.8217 70.2331 61.547 67.6788 63.0217L40.1872 78.894C37.633 80.3687 34.4861 80.3687 31.9318 78.894L4.44021 63.0217C1.88598 61.547 0.312501 58.8217 0.312501 55.8723L0.3125 24.1277C0.3125 21.1783 1.88597 18.453 4.44021 16.9783L31.9318 1.10602Z"
  );
}

.profile__name {
  display: block;
  font-size: 1.25rem;
  font-weight: 800;
  color: #292a2c;
}

.profile__level {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: #f2ecf8;
  border-radius: 4px;
  gap: 8px;
  color: #8d5dc6;
  font-size: 0.625rem;
  margin: 5px 0 0;
}

.profile__level span {
  font-size: 1.1em;
}

.header__actions {
  display: flex;
  gap: 15px;
  margin-top: 5px;
  justify-content: space-between;
  width: 100%;
  transition: padding 300ms ease;
}

.header__actions--expand {
  padding-left: 105px;
}

.header__actions--contract {
  padding-left: 260px;
}

.actions__button {
  width: 40px;
  height: 40px;
  background-color: #f4f4f4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button--profile-dropdown {
  text-align: left;
  cursor: auto;
}

.navigation-bar__container {
  display: flex;
  gap: 30px;
}

.navigation-bar__links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding-left: 30px;
}

@media (max-width: 1023px) {
  .button--logout {
    display: none;
  }

  .button--profile-dropdown {
    display: none;
  }

  .navigation-bar__container {
    display: none;
  }

  .header__actions {
    width: auto;
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .header {
    justify-content: flex-end;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  }

  .header__profile {
    display: none;
  }

  .button--menu {
    display: none;
  }

  .button--logout {
    margin-left: 40px;
  }

  .profile__welcome {
    font-size: 32px;
    font-weight: 400;
  }

  .profile__welcome span {
    font-weight: 700;
  }
}
