.Done {
  background-color: #f8fff3;
  cursor: pointer;
}

.InProgress {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  cursor: pointer;
}

.InProgress:hover {
  background-color: #e1e2e3;

  transition: color 0.3s, background-color 0.3s;
}

.Title {
  font-size: 18px;
  font-weight: 500;
}

.Description {
  font-size: 15px;
  font-weight: 400;
}
