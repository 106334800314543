.banner_premium {
  position: relative;
  height: 150px;
  background-color: #fed123;
  border-radius: 8px;
  padding: 20px 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  cursor: pointer;
}

.banner_premium > p:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: white;
}

.banner_premium > div > p {
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  color: white;
}

.banner_premium > p:nth-child(2) {
  color: #6a51b2;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  display: flex;
}

.banner_premium > button {
  font-weight: 800;
  font-size: 14px;
  line-height: 8px;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 20px 0;
}

.banner_premium::after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 6px;
  background-color: #6a51b2;
  border-radius: 0px 0px 8px 8px;
}

@media screen and (max-width: 425px) {
  .banner_premium {
    background-position: right;
    padding: 20px;
  }
}
