.wrapper {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 0px #dddddd;
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer {
  border: 1px solid #e0e0e6;
  box-shadow: 0px 3px 0px #e0e0e6;
  height: 64px;
  font-size: 17px;
  color: #36344b;
}

.callToAction {
  max-width: 430px;
  font-size: 16px;
  padding: 13px 0;
  color: rgba(54, 52, 75, 0.21);
}

.answerChecking {
  background: #f2ecf8;
  border: 1px solid #6a51b2;
  box-shadow: 0px 3px 0px #6a51b2;
}

.answerCorrect {
  color: #58a700;
  background: #d7ffb8;
  border: 1px solid #58a700;
  box-shadow: 0px 3px 0px #58a700;
}

.answerIncorrect {
  color: #ea2b2b;
  background: #ffdfe0;
  border: 1px solid #ea2b2b;
  box-shadow: 0px 3px 0px #ea2b2b;
}

.callToActionChecking {
  color: #8d5dc6;
  background-color: #8d5dc6;
  box-shadow: 0px 3px 0px #6a51b2;
  color: white;
}

.callToActionCorrect {
  color: #58cc02;
  background: #58cc02;
  box-shadow: 0px 3px 0px #58a700;
  color: white;
}

.callToActionIncorrect {
  color: #ff4b4b;
  background: #ff4b4b;
  box-shadow: 0px 3px 0px #ea2b2b;
  color: white;
}

.disabled {
  background: #f4f4f4;
  color: #bbbbbb;
}

.wrapper:disabled {
  cursor: default;
  pointer-events: all !important;
}

.image {
  height: 40px;
  width: 40px;
}
