.lesson-item {
  display: flex;
  width: 100%;
  height: 96px;
  background-color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  transition: 0.1s ease-in-out background-color;
}

.lesson-item:hover {
  background-color: #f0f0f0;
}

.lesson-item > div {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
}

.lesson-item__datails {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.lesson-item__icon {
  width: 29px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6a51b2;
  clip-path: path(
    "M12.6361 0.442407C13.6569 -0.147469 14.9145 -0.147469 15.9353 0.442407L26.9219 6.79132C27.9426 7.3812 28.5714 8.47133 28.5714 9.65108L28.5714 22.3489C28.5714 23.5287 27.9426 24.6188 26.9219 25.2087L15.9353 31.5576C14.9145 32.1475 13.6569 32.1475 12.6361 31.5576L1.64957 25.2087C0.628814 24.6188 4.39966e-07 23.5287 4.17906e-07 22.3489L1.80467e-07 9.65108C1.58407e-07 8.47133 0.628813 7.3812 1.64957 6.79132L12.6361 0.442407Z"
  );
}

.icon--done {
  background-color: #27ae60;
}

.icon--block {
  background-color: #f2f2f2;
}

.icon--block svg path {
  stroke: #939393;
}

.lesson-item__title {
  width: 90%;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.lesson-item__legend {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  opacity: 0.1;
}

.legend--done {
  color: #27ae60;
  opacity: 1;
}

.btn-see-lesson {
  background-color: #ddceee;
  width: 50px;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.button--done {
  background-color: #b4eecd;
}

.button--block {
  background-color: #f2f2f2;
  cursor: default;
}

.btn-see-lesson:disabled::before {
  content: "Assine um plano para ter acesso.";
  position: absolute;
  right: 0;
  width: 200px;
  padding: 8px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  background-color: #000;
  color: white;
  margin-right: 60px;
  display: none;
}

.btn-see-lesson:disabled::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #000;
  margin-right: 73px;
  transform: rotate(45deg);
  display: none;
}

.btn-see-lesson:disabled:hover::after {
  display: block;
}

.btn-see-lesson:disabled:hover::before {
  display: block;
}
