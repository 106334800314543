.header-bg {
  background: url("../../../../../assets/imgs/Points.png");
  background-color: #5708b6;
  height: 14em;
  background-position: right;
  border-radius: 0px 0px 45px 0px;
}

@media (min-width: 640px) {
  .header-bg {
    background: url("../../../../../assets/imgs/Points.png");
    background-color: #5708b6;
    height: 10em;
    background-position: right;
    border-radius: 0px 0px 45px 0px;
  }
}
