.banner__teacher__square {
  width: 236px;
  background: #23c1c9;
  border-radius: 8px;
  padding-top: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  cursor: pointer;
}

.banner__teacher__square > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner__teacher__square > div > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: white;
  display: inline-block;
  padding: 10px;
}

.banner__teacher__square > div span > span {
  color: #fdc93a;
  font-weight: 700;
  text-align: center;
}

.banner__teacher__square > div button {
  font-weight: 800;
  font-size: 14px;
  line-height: 8px;
  text-transform: uppercase;
  color: #fdc93a;
  display: flex;
  align-items: center;
  justify-content: center;
}
