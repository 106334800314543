.banner-certificate {
  max-height: 150px;
  background-color: #fed123;
  border-radius: 8px;
  padding: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  cursor: pointer;
}

.banner-certificate > span {
  display: block;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: -0.2px;
  color: #7c54ea;
  margin-bottom: 20px;
  width: 50%;
  line-height: 25px;
}

.banner-certificate > span > span {
  font-weight: 800;
}

.banner-certificate > button {
  font-weight: 800;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: #fff;
  background-color: #f36a8d;
  gap: 10px;
  padding: 5px 20px;
  border-radius: 100px;
}
