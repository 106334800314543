.slide-component {
  -webkit-animation: slide 1s forwards;
  animation: slide 0.5s ease forwards;
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
  height: 100vh;
}

.slide-close {
  -webkit-animation: slideOff 0.9s forwards;
  animation: slideOff 0.5s forwards;
}

@-webkit-keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOff {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slideOff {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
