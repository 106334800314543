#go-class-button {
  color: #FFC300;
  border: 1px solid #FFC300;
  cursor: pointer;
}

#go-class-button:disabled {
  background-color: #eee;
  border: none;
  color: #bbb;
  cursor: not-allowed;
}
