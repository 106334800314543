.buttonColored {
  background-color: white;
  border: solid 1px rgba(88, 7, 184, 1);
  color: rgba(88, 7, 184, 1);

  /* background: #5807b8; */
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
  /* color: #ffffff; */
}
