.wrapper {
  height: calc(100dvh - 160px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.wrapperButton {
  width: 345px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tryAgainButton {
  color: #8d5dc6;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.percentage {
  font-weight: 800;
  font-size: 18px;
  line-height: 13px;
  margin-top: 20px;
}

.title {
  width: 416px;
  font-weight: 800;
  font-size: 32px;
  line-height: 29px;
  color: #262626;
}

.text {
  width: 326px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.textSuccess {
  width: 416px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.wrapperContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  text-align: center;
}

@media (max-width: 500px) {
  .title {
    width: 216px;
    font-size: 32px;
  }

  .text {
    width: 226px;
    font-size: 16px;
  }

  .textSuccess {
    width: 100%;
    font-size: 18px;
  }

  .wrapperButton {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
