.Backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
}

.Container {
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px;
  max-width: 380px;
  margin: 0 auto;
}

.Price {
  letter-spacing: -2px;
  font-weight: 700;
  color: #EE56BB;
  font-size: 36px;
}

.Wrapper {
  width: 100%;
  background-color: white;
  border-radius: 0 0 19px 19px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Image {
  width: 100%;
  border-radius: 19px 19px 0 0;
}

.WrapperButtonClose {
  position: absolute;
  top: 10px;
  right: 12px;
}

.ButtonClose {
  background-color: white;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.Title {
  line-height: 29px;
  color: #424242;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  max-width: 240px;
}

.Description {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  color: #313131;
  margin-bottom: 24px;
}

.DescriptionItem {
  display: flex;
  align-items: center;
  background-color: #733EB1;
  padding: 0px 5px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.DescriptionItem span {
  color: #A8FE68;
  margin-right: 5px;
  font-size: 24px;
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px;
  background-color: #EE56BB;
  border-radius: 12px;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 8px;
}
