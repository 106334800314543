.material-card {
  background-color: #fbfcfd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 430px;
  transition: 0.1s ease-in-out background-color;
}

.material-card:hover {
  background-color: #f0f0f0;
}

.material-card__image {
  height: 80px;
  margin-bottom: 10px;
}

.material-card__title {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.material-card__description {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
  margin: 15px 0 5px;
  height: 45px;
}

.material-card__details {
  font-weight: 800;
  font-size: 14px;
  color: #733eb1;
  display: flex;
  margin: 10px 0 20px;
  justify-content: center;
  width: 100%;
}

.material-card__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: 40px;
  background: #733eb1;
  border-radius: 8px;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-top: auto;
}
