.sidebar {
  width: 250px;
  background-color: #fff;
  min-height: 100vh;
  padding: 45px 30px 30px;
  position: fixed;
  transition: transform 300ms ease, width 300ms ease;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 16px;
}

.sidebar--hidden {
  transform: translateX(-100%);
}

.toggle-menu {
  width: 96px;
  padding: 50px 0 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.toggle-menu .button--toggle-menu svg {
  transform: rotate(180deg);
}

.container-links {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 30px;
}

.sidebar__link {
  align-items: center;
  color: #b3b8c0;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  gap: 15px;
  justify-content: flex-start;
  padding: 8px 11px;
  width: 100%;
  position: relative;
}

.sidebar__link:hover svg {
  filter: invert(45%) sepia(69%) saturate(420%) hue-rotate(225deg) brightness(75%) contrast(110%);
}

.sidebar__link:hover {
  color: #8d5dc6;
}

.sidebar__link--active {
  color: #8d5dc6;
}

.sidebar__link--active svg {
  filter: invert(45%) sepia(69%) saturate(420%) hue-rotate(225deg) brightness(75%) contrast(110%);
}

.sidebar__link:hover .link__tooltip {
  display: block;
}

.link__tooltip {
  position: absolute;
  right: 0;
  background-color: #222;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
  transform: translate(100%, 0);
  display: none;
  font-size: 14px;
  white-space: nowrap;
}

.link__tooltip:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 10px solid #000;
  position: absolute;
  left: 0;
  top: 0;
  border-color: transparent transparent #222 transparent;
  transform: rotate(-90deg);
  margin: 5px 0 0 -17px;
}

.button--toggle-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6a51b2;
  width: 34px;
  height: 38px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 178px -17px 0 0;
  clip-path: path(
    "M15.037 0.525358C16.2517 -0.17512 17.7483 -0.17512 18.963 0.525358L32.037 8.06469C33.2517 8.76517 34 10.0597 34 11.4607L34 26.5393C34 27.9403 33.2517 29.2348 32.037 29.9353L18.963 37.4746C17.7483 38.1751 16.2517 38.1751 15.037 37.4746L1.96299 29.9353C0.748289 29.2348 5.2246e-07 27.9403 4.96263e-07 26.5393L2.14305e-07 11.4607C1.88108e-07 10.0597 0.748288 8.76517 1.96299 8.0647L15.037 0.525358Z"
  );
}

.button--toggle-menu svg path {
  fill: #fff;
}

.hidden-text {
  display: none;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

.medias__links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.medias__links > a {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1023px) {
  .button--toggle-menu {
    display: none;
  }

  .sidebar {
    padding-top: 20px;
  }
}

@media screen and (max-height: 850px) {
  .logo-footer-menu {
    display: none;
  }
}

@media screen and (max-height: 800px) {
  .sidebar__link {
    padding: 2px 11px;
  }
}

@media screen and (max-height: 740px) and (min-width: 1024px) {
  .sidebar__link {
    padding: 0 11px;
  }
}
