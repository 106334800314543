.back-button__container  {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-weight: 400;
  font-size: 18px;
}

.back-button__container button {
  width: 40px;
  height: 40px; 
  background: #F2ECF8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
