.WrapperDesktop {
  border-radius: 12px;
  height: 130px;
  cursor: pointer;
  background: url('../../../../assets/imgs/banner-7-dias-desktop.png');
  background-repeat: round;
  background-size: cover;
  padding: 26px;
  color: white;
}

.WrapperDesktop p {
  font-size: 22px;
}

.WrapperDesktop > button {
  font-weight: 700;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  color: #532D80;
  background-color: white;
  gap: 8px;
  padding: 3px 18px;
  border-radius: 100px;
  margin-top: 10px;
}


.TextGreen {
  color: #A8FE68;
}

.Wrapper {
  border-radius: 8px;
  height: 92px;
  cursor: pointer;
  background: url('../../../../assets/imgs/banner-7-dias.png');
  background-repeat: round;
  background-size: cover;
}

.TextWrapper {
  padding-left: 15px;
  padding-top: 12px;
}

.TextWrapper > p {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: white;
  max-width: 150px;
}

.TextWrapper > button {
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;
  text-transform: uppercase;
  color: #532D80;
  background-color: white;
  gap: 4px;
  padding: 1px 12px;
  border-radius: 100px;
  margin-top: 10px;
}
