.Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 740px;
  max-height: 95vh;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.HeaderInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Avatar {
  position: relative;
}

.Avatar img {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.Avatar .Status {
  position: absolute;
  background-color: rgb(105, 222, 64);
  border-radius: 50%;
  border: 1px solid #fff;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
}

.HeaderInfo div h3 {
  font-size: 16px;
  font-weight: 500;
}

.HeaderInfo div span {
  font-size: 14px;
  color: #666;
}

.Conversation {
  flex: 1;
  background-color: rgb(234, 238, 243);
  overflow-y: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  width: 100%;
}

.Conversation::before {
  content: "";
  display: block;
  height: 20px;
}

.Footer {
  border-top: 1px solid #f0f0f0;
}

.Footer form {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgb(255, 255, 255);
  border-top-color: rgb(234, 234, 234);
}

.Footer input {
  flex: 1;
  border-radius: 20px;
  border: 1px solid #d4d4d8;
  padding: 10px;
}

.Footer input:focus {
  outline: 1px solid #733eb1 !important;
}

.Footer input:disabled {
  background-color: #e4e4e7;
}

.Footer input:disabled::placeholder {
  color: #6b7280;
}

.Footer button {
  width: 46px;
  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Iframe {
  width: 100%;
  height: 230px;
  overflow: hidden;
}

.Footer button:disabled > svg {
  color: #6b7280;
}

.Footer .CallButton {
  color: #fff;
  border: 1px solid #d4d4d8;
  border-radius: 100%;
}

.CallButton:hover {
  background-color: #d4d4d8;
  transition: color cubic-bezier(0.075, 0.82, 0.165, 1);
}

.CopyRight {
  padding: 12px;
  font-size: 12px;
  text-align: center;
  border-top-color: rgb(238, 238, 238);
  background-color: rgb(249, 249, 249);
  border-radius: 20px;
}

.CopyRight span {
  font-weight: bold;
  color: #733eb1;
}

@media screen and (max-width: 470px) {
  .Wrapper {
    width: 100%;
    height: 100dvh;
    max-height: 100vh;
    z-index: 9999;
  }

  .Iframe {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
}
