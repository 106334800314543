.buttonLesson {
  width: 60%;
}

.notificationArea {
  border: 1px solid #f3f4f6;
  border-radius: 15px;
}

.bellBackground {
  background-color: #ffe799;
  opacity: 0.5;
}

.warningTextColor {
  color: #f59300;
}

.cancelLesson {
  color: #b81212;
  border: 1px solid #b81212;
}

.changeProfessor {
  color: #5807b8;
  border: 1px solid #5807b8;
}

.borderButtons {
  border-top: 1px solid rgba(0, 0, 0, 0.055);
}
