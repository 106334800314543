.warning {
  background: #FCE4BF;
  color: #4F4F4F;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
}

.warning strong {
  color: #000;
}
