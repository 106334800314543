.favoriteButton {
  border: 1px solid #14c4be;
  border-radius: 0.5em;
  padding: 1em;
  letter-spacing: 0.05em;
  color: #403f41;
}

.borderBotton {
  cursor: pointer;
  padding-bottom: 0.75em;

  color: rgba(157, 150, 163, 1);
  border-bottom: 3px solid rgba(182, 182, 182, 0.63);
}

.selectedBotton {
  cursor: pointer;
  padding-bottom: 0.75em;
  color: rgba(20, 196, 190, 1);
  border-bottom: 3px solid rgba(20, 196, 190, 1);
}

.borderEnsino {
  cursor: pointer;
  padding-bottom: 0.75em;
  width: 50%;
  color: rgba(157, 150, 163, 1);
  border-bottom: 3px solid rgba(182, 182, 182, 0.63);
}

.selectedIntroduction {
  cursor: pointer;
  padding-bottom: 0.75em;
  color: rgba(20, 196, 190, 1);
  border-bottom: 3px solid rgba(20, 196, 190, 1);
}

.borderLeftCurriculum {
  border-left: 2px solid rgba(234, 234, 245, 1);
}

@media (max-width: 1366px) {
  .teacher-banner {
    height: 11rem;
  }
}

@media (min-width: 1367px) {
  .teacher-banner {
    height: 16rem;
  }
}
