.UserMessage {
  word-wrap: break-word;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  max-width: 100%;
  padding: 15px 17px;
  white-space: pre-wrap;
  word-break: break-word;
  border-bottom-right-radius: 5px;
  background: #733eb1;
  color: rgb(255, 255, 255);
}
