.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: transparent;
  pointer-events: all;
}

.unityLevel {
  font-weight: 600;
  font-size: 14.0187px;
  text-transform: uppercase;
}

.headerButton {
  background-color: white;
  border-radius: 50%;
}

.closeButton path {
  stroke: #929292;
}
