.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.unityLevel {
  font-weight: 600;
  font-size: 14.0187px;
  text-transform: uppercase;
}

.closeButton path {
  stroke: #929292;
}
