.button__go {
  background-color: #623cea;
  position: relative;
}

.button__go:hover {
  background-color: #663ff3;
}

.button__go:disabled {
  background-color: #eee;
  color: #bbb;
  cursor: default;
}

.button__go span {
  align-items: center;
  background-color: black;
  border-radius: 10px;
  color: white;
  display: none;
  font-size: 12px;
  inset: 0;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  transform: translate(0, calc(-100% - 10px));
  width: 280px;
}

.button__go span::after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -7px;
  background-color: black;
  transform: rotate(45deg);
}

.button__go:disabled:hover span {
  display: flex;
}

.btn-event-disabled {
  cursor: no-drop;
  border: transparent;
  background-color: #808080a3 !important;
}
