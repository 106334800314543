.wrapper {
  height: calc(100vh - 30px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.wrapperFlex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.brandingWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.wrapperButton {
  gap: 20px;
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.improveButton {
  color: #8d5dc6;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
}

.percentage {
  font-weight: 800;
  font-size: 18px;
  line-height: 13px;
  margin-top: 20px;
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #1cb2f8;
  width: 300px;
}

.text {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #656565;
}

.wrapperContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  text-align: center;
}

.shareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: #1cb2f8;
  box-shadow: 0px 3px 0px #1986b9;
  border-radius: 8px;
}

.shareText {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #1cb2f8;
}

.LinkedinButton {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 30px;
}

.LinkedinButton img {
  width: 20px;
  height: 20px;
}

.certificatesButton {
  height: 50px;
  width: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f36a8d;
  color: white;
  padding: 12px;
  gap: 12px;
  box-shadow: 0px 3px 0px #bf4c69;
  border-radius: 99px;
  font-weight: 800;
  font-size: 14px;
}

@media (max-width: 500px) {
  .shareText {
    font-weight: 900;
    font-size: 14px;
    line-height: 15px;
    color: #1cb2f8;
  }

  .LinkedinButton {
    padding: 10px;
  }
}
