.lesson-cover__container {
  width: 100%;
  aspect-ratio: 16/9;
  background-size: cover;
  display: flex;
  cursor: pointer;
}

.lesson-cover__container > div {
  background-color: white;
  width: 48%;
  height: 100%;
  margin-left: auto;
  border-radius: 8px 0 0 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.lesson-cover__title {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000;
  margin-bottom: 10px;
}

.lesson-cover__title span {
  color: #47d5dc;
  font-weight: 800;
}

.lesson-cover__description {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #333;
  margin-bottom: 20px;
}

@media screen and (max-width: 440px) {
  .lesson-cover__container > div {
    width: 60%;
  }
}
/*
@media screen and (min-width: 1024px) {
  .container__video__wrapper {
    min-width: 500px;
  }
} */

@media screen and (min-width: 1500px) {
  .lesson-cover__title {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .lesson-cover__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}
