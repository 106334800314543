.generic-input__label {
  font-weight: 500;
  font-size: 16px;
}

.generic-input__error-message {
  color: red;
  font-weight: 600;
  font-size: 12px;
  display: block;
}

.border-error {
  border: 2px solid red !important
}

.generic-input {
  background: #F3F3F3;
  border-radius: 8px;
  padding: 15px;
  height: 56px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  display: block;
  color: #828282;
  margin-top: 2px;
  border: 2px solid transparent;
}

.generic-input:focus {
  background-color: white;
  border: 2px solid #121212;
  color: #121212;
  font-weight: 700;
}

.generic-input::-webkit-input-placeholder {
  color: #828282;
}

.generic-input:-moz-placeholder { /* Firefox 18- */
  color: #828282;  
}

.generic-input::-moz-placeholder {  /* Firefox 19+ */
  color: #828282;  
}

.generic-input:-ms-input-placeholder {  
  color: #828282;  
}
