.main-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px); /* subtrai a altura da barra de ad */
  width: 100%;
}

.main-wrapper span,
.main-wrapper button {
  color: #36344b;
  font-family: "Nunito";
}

.questions-container {
  margin: auto;
  overflow-x: hidden;
  padding: 20px 0;
  width: 100%;
}

.questions {
  display: flex;
  transition: all 0.8s cubic-bezier(0.65, -0.35, 0.3, 1.07);
}

.progress-bar {
  background-color: #efefef;
  border-radius: 100px;
  height: 18px;
  margin: 50px auto 0;
  max-width: calc(100% - 100px);
  width: 100%;
}

.progress-fill {
  background-color: #623cea;
  border-radius: 100px;
  height: 100%;
  padding-top: 3px;
  transition: all 0.8s cubic-bezier(0.65, -0.35, 0.3, 1.07);
  width: 20%;
}

.fill-glow {
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 100px;
  height: 6px;
  margin: 0 auto;
  width: calc(100% - 20px);
}

.onboarding-phone-wrapper {
  width: 328px;
  margin: 0 auto;
}

@media screen and (max-width: 576px) {
  .progress-bar {
    margin: 30px auto 30px;
    max-width: calc(100% - 60px);
  }
}
