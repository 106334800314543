.Wrapper {
  inset: 0;
  position: fixed;
  overflow: auto;
  z-index: 999999;
}
.Bg {
  height: 100%;
  width: 100%;
  background: rgba(19, 19, 20, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  background-color: white;
  height: 430px;
  padding: 15px 18px 18px;
  border-radius: 5%;
  margin-top: -30px;
  max-width: 375px;
}

.BannerWrapper {
  border-radius: 8px;
  height: 122px;
}

.TextWrapper {
  padding-left: 22px;
  padding-top: 30px;
}

.BannerTitle {
  font-size: 18px;
  color: white;
  font-weight: 700;
  line-height: 20px;
}

.SubTitle {
  font-size: 26px;
  font-weight: 700;
  color: white;
  line-height: 32px;
}

.SubTitle span {
  color: #A8FE68;
}

.Title {
  text-align: center;
  font-weight: 700;
  font-size: 21px;
  color: #48276F;
  margin: 15px 0;
}

.Description {
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #7D7288;
  margin: 15px;
}

