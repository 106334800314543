.modal-banner-certificate {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.modal-banner-certificate-button-wrapper {
  width: 300px;
}

.modal-banner-certificate-button-outline {
  width: 100%;
  height: 47px;
  font-weight: 400;
  font-size: 20px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #723fb1;
  border: 1.5px solid #723fb1;
  gap: 10px;
  background: #ffffff;
  border-radius: 16px;
}

.modal-banner-certificate-button-primary {
  width: 100%;
  height: 47px;
  padding: 15px 10px;
  font-weight: 800;
  font-size: 20px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #723fb1;
  gap: 10px;
  color: #ffffff;
  border-radius: 16px;
  margin-top: 10px;
}

.modal-banner-certificate-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-banner-certificate-title > span {
  font-weight: bold;
  color: #723fb1;
}

.modal-banner-certificate-title {
  width: 200px;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
  color: black;
  margin-bottom: 10px;
}

.modal-banner-certificate-description {
  width: 280px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin-top: 16px;

  color: black;
  margin-bottom: 10px;
}
