.signup-wrapper {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 30px;
  width: 100%;
}

.signup-wrapper * {
  color: #36344b;
  font-family: "Nunito";
}

.signup__header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 480px;
}

.header__title {
  display: block;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 10px;
  text-align: center;
}

.header__description {
  color: rgba(54, 52, 75, 0.5);
  font-size: 1.2rem;
  text-align: center;
}

.signup__form {
  max-width: 480px;
}

.signup__form > input {
  background-color: white;
  border: 1px solid #ddddddb5;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 15px;
  width: 100%;
}

.btn-signup {
  background-color: #623cea;
  border: 1px solid #482daa;
  border-radius: 10px;
  box-shadow: 0px 3px 0px #482daa;
  color: white;
  display: block;
  font-size: 1.1rem;
  font-weight: 700;
  margin: 20px auto 0;
  padding: 12px 40px;
  width: 100%;
}

.btn-signup:hover {
  background-color: #6941fa;
}

.signup__form .invalid-email {
  color: red;
  border: 1px solid red;
}

.password-message {
  margin-top: -5px;
  display: block;
  color: red;
  font-weight: 700;
}

.have-account-link {
  font-size: 1em;
  font-weight: 700;
  text-decoration: underline;
  text-align: center;
  display: block;
  margin-top: 20px;
}
