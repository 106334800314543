.Wrapper {
  max-width: 230px;
  border-radius: 20px;

  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  flex-shrink: 0;
}

.Wrapper > div {
  padding: 15px 17px;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-wrap;
}

.Wrapper > div > h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.Wrapper > div > img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

.Wrapper > button {
  width: 100%;
  height: 45px;
  color: black;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  border-top: 1px solid #d4d4d8;

  text-align: center;
  color: #733eb1;
  font-weight: bold;
}

.Wrapper > button:hover {
  opacity: 0.8;
  transition: opacity cubic-bezier(0.165, 0.84, 0.44, 1);
}
