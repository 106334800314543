.materials__container {
  padding: 20px 30px;
  margin-bottom: 100px;
}

.materials__container .rec-item-wrapper {
  height: 100%;
}

.materials__button-action {
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  height: 52px;
  background: #FFFFFF;
  border-radius: 12px;
}

.materials__container .rec-slider-container {
  margin: 0;
}

.input__search {
  flex: 1;
  width: 100%;
  padding-left: 12px;
  font-weight: 600;
}

@media screen and (max-width: 1023px) {
  .materials__container {
    padding: 15px;
  }
}
