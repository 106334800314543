.container__plans {
  height: calc(100vh - 330px);
  /* gap: 40px; */
}

@media screen and (max-width: 768px) {
  .container__plans {
    flex-direction: column;
    width: calc(100vw - 65px);
    align-items: center;
    /* gap: 0; */
  }
}
