.lessons-list__container {
  padding: 15px;
  margin-bottom: 100px;
}

.container__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header__col {
  width: 100%;
}

.lessons__progress-details {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
}

.progress-details__header {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.progress-details__header img {
  width: 25px;
}

.category__image--lg {
  display: none;
}

.progress-details__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-weight: 800;
  font-size: 16px;
  color: #6a51b2;
}

.footer__total-materials {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
}

@media screen and (min-width: 1023px) {
  .lessons-list__container {
    padding: 40px;
  }

  .container__header {
    flex-direction: row;
    gap: 20px;
  }

  .header__col {
    width: 50%;
  }

  .header__col:nth-child(2) {
    max-width: 550px;
  }

  .lessons__progress-details {
    background-color: transparent;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0;
  }

  .lessons__progress-details > div {
    width: 100%;
  }

  .category__image--lg {
    display: block;
    width: 70px;
  }

  .progress-details__header img {
    display: none;
  }
}
