.banner__private__classes__square {
  width: 236px;
  background: #d2e23d;
  border-radius: 8px;
  padding-top: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  cursor: pointer;
}

.banner__private__classes__square > div > p {
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: #6a51b2;
  display: inline-block;
}

.banner__private__classes__square > div > p > span {
  color: #f36a8d;
  font-weight: 700;
  text-align: center;
}

.banner__private__classes__square > div > button {
  font-weight: 800;
  font-size: 12px;
  line-height: 8px;
  text-transform: uppercase;
  color: #6a51b2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 20px auto;
  margin-top: 2px;
}
