.Container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 500;
}

.ChatButton {
  cursor: pointer;
}

.ChatButton img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.Close {
  position: absolute;
  right: -5px;
  top: -7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-color: #a1a1aa;
  z-index: 2;
  border-radius: 50%;
  padding: 2px;
}

/* @Midia para o Chatbot na resolução de Mobile */

@media screen and (max-width: 470px) {
  .Container {
    right: 0;
    bottom: 0;
    z-index: 500;
  }

  .ChatButton img {
    width: 80px;
    height: 80px;
  }
}
