.progressBar {
  background-color: #efefef;
  border-radius: 100px;
  width: 100%;
}

.progressBarDefault {
  height: 8px;
}

.progressBarExercise {
  height: 14px;
}

.progressBarExercise .progressFill {
  background-color: #623cea;
  border-radius: 100px;
  height: 100%;
  width: 100%;
  padding-top: 3px;
  transition: all 0.8s ease;
}

.progressBarDefault .progressFill {
  background-color: #8d5dc6;
  border-radius: 100px;
  height: 100%;
  width: 100%;
  transition: all 1s ease;
}

.fillGlow {
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 100px;
  height: 6px;
  margin: 0 auto;
  width: calc(100% - 20px);
}
