.banner_pratice_conversation_square {
  width: 236px;
  background: #f36a8d;
  border-radius: 8px;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  cursor: pointer;
}

.banner_pratice_conversation_square > div > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: white;
  display: inline-block;
}

.banner_pratice_conversation_square > div span > span {
  color: #fdc93a;
  font-weight: 700;
  text-align: center;
}

.banner_pratice_conversation_square > div button {
  font-weight: 800;
  font-size: 12px;
  line-height: 8px;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 5px auto;
}

@media (max-width: 1023px) {
  .banner_pratice_conversation_square {
    flex-direction: row;
    width: 100%;
    padding: 10px 10px 10px 20px;
    align-items: center;
    gap: 5px;
  }

  .banner_pratice_conversation_square > div > div {
    text-align: left;
    width: 50%;
  }

  .banner_pratice_conversation_square img {
    width: 50%;
    aspect-ratio: 3/2;
  }

  .banner_pratice_conversation_square > div > span {
    text-align: left;
    font-size: 17px;
    line-height: 20px;
  }

  .banner_pratice_conversation_square button {
    margin: 20px 0 0;
  }
}
