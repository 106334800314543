.accountRecoverySucess h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #383743;
}

.accountRecoverySucess .personIcon {
  margin: 0 auto;
  margin-top: 24px;
}

.accountRecoverySucess button {
  margin-top: 24px;
  width: 100%;
  height: 52px;
  background: #5807b8;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #ffffff;
}
