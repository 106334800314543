.event-card {
  background: #E7F8FF;
  border-radius: 9px;
  padding: 15px;
  margin-bottom: 20px;
}

.event-card__header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.event-card__header span {
  font-weight: 700;
  font-size: 14px;
  color: #6AAFDD;
}

.event-card__info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.event-card__info--title {
  font-weight: 900;
  font-size: 10px;
  color: #292A2C;
  text-transform: uppercase;
}

.event-card__info--value {
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: #6AAFDD;
}

.event-card__button {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0;
  font-weight: 800;
  font-size: 13px;
  color: #6AAFDD;
  padding: 8px;
}

@media screen and (min-width: 1024px) {
  .event-card__button {
    display: none;
  }

  .event-card {
    cursor: pointer;
  }
}