.banner-private-classes {
  position: relative;
  min-height: 150px;
  background: #d2e23d;
  border-radius: 8px;
  padding: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  cursor: pointer;
}

.banner-private-classes > span {
  display: block;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.2px;
  color: #6a51b2;
  margin-bottom: 20px;
  width: 50%;
  line-height: 25px;
}

.banner-private-classes > span > span {
  font-weight: 800;
  color: #f36a8d;
}

.banner-private-classes > button {
  font-weight: 800;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: #6a51b2;
  gap: 10px;
}

.banner-private-classes::after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 6px;
  background-color: #6a51b2;
  border-radius: 0px 0px 8px 8px;
}
