.Wrapper {
  width: 238px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1;
  border: 1px solid #a1a1aa;
  background-color: #fff;
  cursor: pointer;
}

.Message {
  display: flex;
  align-items: center;
}

.Message > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.Message > p {
  margin: 0;
  line-height: 1.1;
  font-size: 16px;
  font-weight: 400;
}

.Close {
  position: absolute;
  right: -5px;
  top: -7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-color: #a1a1aa;
  z-index: 2;
  border-radius: 50%;
  padding: 2px;
}
