.LessonItemHover:hover {
  background-color: #f7f0ff;
  cursor: pointer;

  transition: color 0.3s, background-color 0.3s;
}

.LessonItemActive {
  background-color: #f7f0ff;
  cursor: pointer;
}

.Title {
  font-size: 16px;
}

.Description {
  font-size: 15px;
  font-weight: 400;
}

.UnityName {
  font-size: 15px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .Title {
    font-size: 12px;
    max-width: 200px;
  }

  .Description {
    font-size: 13px;
  }

  .UnityName {
    font-size: 13px;
  }
}
