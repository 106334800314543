.modal-banner-conversation {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.modal-banner-conversation-button-wrapper {
  width: 300px;
}

.modal-banner-conversation-button-outline {
  width: 100%;
  height: 47px;
  font-weight: 400;
  font-size: 20px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ebbb38;
  border: 1.5px solid #ebbb38;
  gap: 10px;
  background: #ffffff;
  border-radius: 16px;
}

.modal-banner-conversation-button-primary {
  width: 100%;
  height: 47px;
  padding: 15px 10px;
  font-weight: 800;
  font-size: 20px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebbb38;
  gap: 10px;
  color: #ffffff;
  border-radius: 16px;
  margin-top: 10px;
}

.modal-banner-conversation-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-banner-conversation-title > span {
  font-weight: bold;
  color: #ebbb38;
}

.modal-banner-conversation-title {
  width: 263px;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
  color: black;
  margin-bottom: 10px;
}

.modal-banner-conversation-description {
  width: 280px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin-top: 16px;

  color: black;
  margin-bottom: 10px;
}
