@media (min-width: 0px) {
  .search-input {
    max-width: 120px;
  }
}

@media (min-width: 360px) {
  .search-input {
    max-width: 150px;
  }
}

@media (min-width: 375px) {
  .search-input {
    max-width: 170px;
  }
}

@media (min-width: 414px) {
  .search-input {
    max-width: 370px;
  }
}

@media (min-width: 768px) {
  .search-input {
    max-width: 120px;
  }
}

@media (min-width: 1024px) {
  .search-input {
    max-width: 370px;
  }
}
