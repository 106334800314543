.events__header div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.events__header div:first-child span {
  font-size: 1rem;
  font-weight: 800;
  color: #292a2c;
}

.events__header div:first-child a {
  padding: 8px 10px;
  background-color: #e7f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #6aafdd;
}

.events__header > span {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: #000000;
  gap: 10px;
  margin: 20px 0;
}

.events__btn-page {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  color: #b3b8c0;
}

.events__btn-page--active {
  background-color: #e7f8ff;
  color: #6aafdd;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {
  .section__events {
    background-color: #fff;
    padding: 15px 15px 1px;
    border-radius: 10px;
    min-width: 300px;
  }
}
